import '../css/project.css';
import '../css/main.css';

// Dynamically import only what's needed for the current route
if (document.getElementById('dashboard-root')) {
  import(/* webpackChunkName: "overview-chunk" */ './overview').then(module => {
    // Initialize overview
  });
} else if (document.getElementById('site-dashboard-root')) {
  import(/* webpackChunkName: "site-chunk" */ './site').then(module => {
    // Initialize site
  });
}
